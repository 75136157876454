<template>
  <div class="wrapper">
    <!-- <div class="banner">
      <img src="../../assets/img/home/banner.png" alt="" />
    </div> -->
    <div class="banner">
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <img
            src="../../assets/img/home/banner/banner1.png"
            class="swiper-slide"
          />
          <img
            src="../../assets/img/home/banner/banner2.jpg"
            class="swiper-slide"
          />
          <img
            src="../../assets/img/home/banner/banner3.jpg"
            class="swiper-slide"
          />
          <img
            src="../../assets/img/home/banner/banner4.png"
            class="swiper-slide"
          />
        </div>
        <!-- 左右箭头(这是自定义写的) -->
        <!-- <img src="../../assets/whalbum/arrow.png" class="swiper-button-next" />
        <img src="../../assets/whalbum/arrow.png" class="swiper-button-prev" /> -->
        <div class="swiper-button-next"></div>
        <div class="swiper-button-prev"></div>
      </div>
    </div>
    <!-- 热门服务 -->
    <div class="container">
      <div class="box">
        <div class="box_title hot_title">
          <div class="hot_icon">
            <img src="../../assets/img/home/hot.png" alt="" />
          </div>
          热门服务
        </div>
        <div class="icon_list">
          <div class="icon_item" @click="serviceClick(7)">
            <div class="icon">
              <img src="../../assets/img/home/ser7.png" alt="" />
            </div>
            <span>免费代账</span>
          </div>
          <div class="icon_item" @click="serviceClick(1)">
            <div class="icon">
              <img src="../../assets/img/home/ser1.png" alt="" />
            </div>
            <span>工商社保</span>
          </div>
          <div class="icon_item" @click="serviceClick(2)">
            <div class="icon">
              <img src="../../assets/img/home/ser2.png" alt="" />
            </div>
            <span>代理记账</span>
          </div>
          <div class="icon_item" @click="serviceClick(3)">
            <div class="icon">
              <img src="../../assets/img/home/ser3.png" alt="" />
            </div>
            <span>支付服务</span>
          </div>
          <div class="icon_item" @click="serviceClick(4)">
            <div class="icon">
              <img src="../../assets/img/home/ser4.png" alt="" />
            </div>
            <span>凭证归集</span>
          </div>
          <div class="icon_item" @click="serviceClick(5)">
            <div class="icon">
              <img src="../../assets/img/home/ser5.png" alt="" />
            </div>
            <span>营销推广</span>
          </div>
          <div class="icon_item" @click="serviceClick(6)">
            <div class="icon">
              <img src="../../assets/img/home/ser6.png" alt="" />
            </div>
            <span>场景服务</span>
          </div>
        </div>
      </div>
    </div>
    <div class="container container_bg">
      <div class="box">
        <div class="title">{{ tabName }}</div>
        <div class="box_main">
          <div class="box_top">
            <div></div>
            <!-- 选项卡切换 -->
            <div class="tab">
              <ul class="tab_list">
                <li
                  class="tab_item"
                  :class="{ tab_active: index === currentIndex }"
                  v-for="(item, index) in tabList"
                  :key="item.id"
                  @click="tabItemClick(index, item.id)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="box_center">
            <div class="left_img">
              <img src="../../assets/img/home/new_act.png" alt="" />
            </div>
            <div class="list">
              <div
                class="item"
                v-for="item in dataNum2"
                :key="item.WZID"
                @click="viewDetail(item.WZID, item.TITLE)"
              >
                <div class="title">{{ item.TITLE }}</div>
                <div class="p">{{ item.DETAILTITLE }}</div>
                <div class="date_box">
                  <div class="date">
                    <span>{{ item.FBSJ.substring(8, 10) }}</span>
                    <span>{{ item.FBSJ.substring(0, 7) }}</span>
                  </div>
                  <div class="arror">
                    <img src="../../assets/img/home/arrow.png" alt="" />
                  </div>
                </div>
              </div>
            </div>

            <div class="actlist">
              <div
                class="actitem"
                v-for="item in dataNum3"
                :key="item.WZID"
                @click="viewDetail(item.WZID, item.TITLE)"
              >
                <div class="actdate_box">
                  <div class="actdate">
                    <span>{{ item.FBSJ.substring(8, 10) }}</span>
                    <span>{{ item.FBSJ.substring(0, 7) }}</span>
                  </div>
                </div>
                <div class="right_title">
                  <div class="acttitle">{{ item.TITLE }}</div>
                  <div class="right_arrow">
                    <img src="../../assets/img/home/right_arrow.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="box_down">
            <div class="more" @click="toList">
              <span>更多</span>
              <img src="../../assets/img/home/more_arrow.png" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- 平台优势 -->
    <div class="container">
      <div class="box">
        <div class="box_title">平台优势</div>
        <div class="content_list">
          <div class="content_item">
            <div class="top">
              <div class="img">
                <img src="../../assets/img/home/plate1.png" alt="" />
              </div>
              <div class="name">央行发牌企业 <span></span></div>
            </div>
          </div>
          <div class="content_item">
            <div class="top">
              <div class="img">
                <img src="../../assets/img/home/plate2.png" alt="" />
              </div>
              <div class="name">中国银联认证企业 <span></span></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper";
import "swiper/dist/css/swiper.css";
export default {
  components: {},
  props: {},
  data() {
    return {
      tabList: [
        { id: 1, name: "最新活动" },
        { id: 2, name: "公共政策" },
      ],
      tabName: "最新活动",
      currentIndex: 0,
      // 活动数据
      dataNum2: [],
      dataNum3: [],
      ggzcData2: [
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "国家税务总局 工业和信息化部关于发布《免征车辆购置税的设有固定装置的非运输专用作业车辆目录》（第十二批）的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00817",
          SFZCJD: "N",
          FBSJ: "2023-11-20 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1784897,
          WZLY: "国家税务总局 工业和信息化部",
          TITLE:
            "国家税务总局 工业和信息化部关于发布《免征车辆购置税的设有固定装置的非运输专用作业车辆目录》（第十二批）的公告",
          SFGQ: "N",
          RN: 1,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "国家税务总局山西省税务局 山西省财政厅 山西省人力资源和社会保障厅 中国人民银行山西省分行 山西省医疗保障局关于优化调整社会保险费申报缴纳流程的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00788",
          SFZCJD: "N",
          FBSJ: "2023-11-17 18:02:30",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1784441,
          WZLY: "国家税务总局山西省税务局 山西省财政厅 山西省人力资源和社会保障厅 中国人民银行山西省分行 山西省医疗保障局",
          TITLE:
            "国家税务总局山西省税务局 山西省财政厅 山西省人力资源和社会保障厅 中国人民银行山西省分行 山西省医疗保障局关于优化调整社会保险费申报缴纳流程的公告",
          SFGQ: "N",
          RN: 2,
          TYPE: "180579",
        },
      ],
      ggzcData3: [
        {
          SFXSSJ: "Y",
          DETAILTITLE: "税务总局更新发布“非接触式”办税缴费事项清单",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00779",
          SFZCJD: "N",
          FBSJ: "2023-11-15 10:22:30",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1784320,
          WZLY: "国家税务总局",
          TITLE: "税务总局更新发布“非接触式”办税缴费事项清单",
          SFGQ: "N",
          RN: 3,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "财政部 税务总局关于确认中国红十字会总会等群众团体2024年度—2026年度公益性捐赠税前扣除资格的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00811",
          SFZCJD: "N",
          FBSJ: "2023-11-13 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1784647,
          WZLY: "财政部 税务总局",
          TITLE:
            "财政部 税务总局关于确认中国红十字会总会等群众团体2024年度—2026年度公益性捐赠税前扣除资格的公告",
          SFGQ: "N",
          RN: 4,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "山西省财政厅 国家税务总局山西省税务局关于明确山西省非营利组织免税资格认定管理有关问题的通知",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00783",
          SFZCJD: "N",
          FBSJ: "2023-11-08 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1784411,
          WZLY: "山西省财政厅 国家税务总局山西省税务局",
          TITLE:
            "山西省财政厅 国家税务总局山西省税务局关于明确山西省非营利组织免税资格认定管理有关问题的通知",
          SFGQ: "N",
          RN: 5,
          TYPE: "180579",
        },
      ],
      activityData2: [
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "全国税务系统新时代“枫桥式”税务所（分局、办税服务厅）建设交流推进会在京召开",
          DQZT: "1",
          LMDM: 2641,
          INDEXNO: "11140000012184142Y/2023-00823",
          SFZCJD: "N",
          FBSJ: "2023-12-04 10:41:05",
          LMMC: "税务新闻",
          YXYJFK: "N",
          WZID: 1785162,
          WZLY: "国家税务总局办公厅",
          TITLE:
            "全国税务系统新时代“枫桥式”税务所（分局、办税服务厅）建设交流推进会在京召开",
          SFGQ: "N",
          RN: 1,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "社保缴费出实招 惠企利民见实效 税务部门深入开展主题教育推动社保缴费更便捷更高效",
          DQZT: "1",
          LMDM: 2641,
          INDEXNO: "11140000012184142Y/2023-00822",
          SFZCJD: "N",
          FBSJ: "2023-12-01 16:45:29",
          LMMC: "税务新闻",
          YXYJFK: "N",
          WZID: 1785136,
          WZLY: "国家税务总局办公厅",
          XQYTITLE:
            '<p style="text-align: center;"><span style="font-size: 28px;">社保缴费出实招 惠企利民见实效</span></p><p style="text-align: center;"><span style="font-size: 22px;">税务部门深入开展主题教育推动社保缴费更便捷更高效</span></p>',
          TITLE:
            "社保缴费出实招 惠企利民见实效 税务部门深入开展主题教育推动社保缴费更便捷更高效",
          SFGQ: "N",
          RN: 2,
        },
      ],
      activityData3: [
        {
          SFXSSJ: "Y",
          DETAILTITLE: "税费服务有力度 惠企便民有温度",
          DQZT: "1",
          LMDM: 2641,
          INDEXNO: "11140000012184142Y/2023-00821",
          SFZCJD: "N",
          FBSJ: "2023-12-01 14:54:10",
          LMMC: "税务新闻",
          YXYJFK: "N",
          WZID: 1785113,
          WZLY: "央广网客户端",
          TITLE: "税费服务有力度 惠企便民有温度",
          SFGQ: "N",
          RN: 3,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "胡静林同志任国家税务总局党委书记",
          DQZT: "1",
          LMDM: 2641,
          INDEXNO: "11140000012184142Y/2023-00818",
          SFZCJD: "N",
          FBSJ: "2023-11-28 11:06:36",
          LMMC: "税务新闻",
          YXYJFK: "N",
          WZID: 1784903,
          WZLY: "国家税务总局办公厅",
          TITLE: "胡静林同志任国家税务总局党委书记",
          SFGQ: "N",
          RN: 4,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "税费优惠助经营主体稳信心 激活力 增动能 今年1-10月全国新增减税降费及退税缓费超1.6万亿元",
          DQZT: "1",
          LMDM: 2641,
          INDEXNO: "11140000012184142Y/2023-00816",
          SFZCJD: "N",
          FBSJ: "2023-11-27 17:23:07",
          LMMC: "税务新闻",
          YXYJFK: "N",
          WZID: 1784864,
          WZLY: "国家税务总局办公厅",
          XQYTITLE:
            '<p style="text-align: center;"><span style="font-size: 28px;">税费优惠助经营主体稳信心 激活力 增动能</span></p><p style="text-align: center;"><span style="font-size: 22px;">今年1-10月全国新增减税降费及退税缓费超1.6万亿元</span></p>',
          TITLE:
            "税费优惠助经营主体稳信心 激活力 增动能 今年1-10月全国新增减税降费及退税缓费超1.6万亿元",
          SFGQ: "N",
          RN: 5,
        },
      ],
      activityList: [
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "全国税务系统新时代“枫桥式”税务所（分局、办税服务厅）建设交流推进会在京召开",
          DQZT: "1",
          LMDM: 2641,
          INDEXNO: "11140000012184142Y/2023-00823",
          SFZCJD: "N",
          FBSJ: "2023-12-04 10:41:05",
          LMMC: "税务新闻",
          YXYJFK: "N",
          WZID: 1785162,
          WZLY: "国家税务总局办公厅",
          TITLE:
            "全国税务系统新时代“枫桥式”税务所（分局、办税服务厅）建设交流推进会在京召开",
          SFGQ: "N",
          RN: 1,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "社保缴费出实招 惠企利民见实效 税务部门深入开展主题教育推动社保缴费更便捷更高效",
          DQZT: "1",
          LMDM: 2641,
          INDEXNO: "11140000012184142Y/2023-00822",
          SFZCJD: "N",
          FBSJ: "2023-12-01 16:45:29",
          LMMC: "税务新闻",
          YXYJFK: "N",
          WZID: 1785136,
          WZLY: "国家税务总局办公厅",
          XQYTITLE:
            '<p style="text-align: center;"><span style="font-size: 28px;">社保缴费出实招 惠企利民见实效</span></p><p style="text-align: center;"><span style="font-size: 22px;">税务部门深入开展主题教育推动社保缴费更便捷更高效</span></p>',
          TITLE:
            "社保缴费出实招 惠企利民见实效 税务部门深入开展主题教育推动社保缴费更便捷更高效",
          SFGQ: "N",
          RN: 2,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "税费服务有力度 惠企便民有温度",
          DQZT: "1",
          LMDM: 2641,
          INDEXNO: "11140000012184142Y/2023-00821",
          SFZCJD: "N",
          FBSJ: "2023-12-01 14:54:10",
          LMMC: "税务新闻",
          YXYJFK: "N",
          WZID: 1785113,
          WZLY: "央广网客户端",
          TITLE: "税费服务有力度 惠企便民有温度",
          SFGQ: "N",
          RN: 3,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "胡静林同志任国家税务总局党委书记",
          DQZT: "1",
          LMDM: 2641,
          INDEXNO: "11140000012184142Y/2023-00818",
          SFZCJD: "N",
          FBSJ: "2023-11-28 11:06:36",
          LMMC: "税务新闻",
          YXYJFK: "N",
          WZID: 1784903,
          WZLY: "国家税务总局办公厅",
          TITLE: "胡静林同志任国家税务总局党委书记",
          SFGQ: "N",
          RN: 4,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "税费优惠助经营主体稳信心 激活力 增动能 今年1-10月全国新增减税降费及退税缓费超1.6万亿元",
          DQZT: "1",
          LMDM: 2641,
          INDEXNO: "11140000012184142Y/2023-00816",
          SFZCJD: "N",
          FBSJ: "2023-11-27 17:23:07",
          LMMC: "税务新闻",
          YXYJFK: "N",
          WZID: 1784864,
          WZLY: "国家税务总局办公厅",
          XQYTITLE:
            '<p style="text-align: center;"><span style="font-size: 28px;">税费优惠助经营主体稳信心 激活力 增动能</span></p><p style="text-align: center;"><span style="font-size: 22px;">今年1-10月全国新增减税降费及退税缓费超1.6万亿元</span></p>',
          TITLE:
            "税费优惠助经营主体稳信心 激活力 增动能 今年1-10月全国新增减税降费及退税缓费超1.6万亿元",
          SFGQ: "N",
          RN: 5,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "晋中税务：税惠助力企业绘就发展新蓝图",
          DQZT: "1",
          LMDM: 2462,
          SFZCJD: "N",
          FBSJ: "2023-12-04 14:54:04",
          LMMC: "基层动态",
          YXYJFK: "N",
          WZID: 1785179,
          WZLY: "国家税务总局山西省税务局",
          TITLE: "晋中税务：税惠助力企业绘就发展新蓝图",
          SFGQ: "N",
          RN: 1,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "忻州税务：“暖心”服务助力企业“舒心”发展",
          DQZT: "1",
          LMDM: 2462,
          SFZCJD: "N",
          FBSJ: "2023-12-01 14:54:22",
          LMMC: "基层动态",
          YXYJFK: "N",
          WZID: 1785112,
          WZLY: "国家税务总局山西省税务局",
          TITLE: "忻州税务：“暖心”服务助力企业“舒心”发展",
          SFGQ: "N",
          RN: 2,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "长治屯留区税务：“政策找人”精准推 定制服务“安心享”",
          DQZT: "1",
          LMDM: 2462,
          SFZCJD: "N",
          FBSJ: "2023-11-27 16:23:52",
          LMMC: "基层动态",
          YXYJFK: "N",
          WZID: 1784840,
          WZLY: "国家税务总局山西省税务局",
          TITLE: "长治屯留区税务：“政策找人”精准推 定制服务“安心享”",
          SFGQ: "N",
          RN: 3,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "太原娄烦税务：助力特色产业 服务乡村振兴",
          DQZT: "1",
          LMDM: 2462,
          SFZCJD: "N",
          FBSJ: "2023-11-24 14:55:27",
          LMMC: "基层动态",
          YXYJFK: "N",
          WZID: 1784747,
          WZLY: "国家税务总局山西省税务局",
          TITLE: "太原娄烦税务：助力特色产业 服务乡村振兴",
          SFGQ: "N",
          RN: 4,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "忻州偏关税务：税惠“添柴” 糜子产业旺起来",
          DQZT: "1",
          LMDM: 2462,
          SFZCJD: "N",
          FBSJ: "2023-11-22 15:21:30",
          LMMC: "基层动态",
          YXYJFK: "N",
          WZID: 1784602,
          WZLY: "国家税务总局山西省税务局",
          TITLE: "忻州偏关税务：税惠“添柴” 糜子产业旺起来",
          SFGQ: "N",
          RN: 5,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "运城税务：税惠添彩 瓜果飘香绘“丰”景",
          DQZT: "1",
          LMDM: 2462,
          SFZCJD: "N",
          FBSJ: "2023-11-20 16:28:56",
          LMMC: "基层动态",
          YXYJFK: "N",
          WZID: 1784500,
          WZLY: "国家税务总局山西省税务局",
          TITLE: "运城税务：税惠添彩 瓜果飘香绘“丰”景",
          SFGQ: "N",
          RN: 6,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "吕梁税务：“税周到”服务为民营经济赋能添力",
          DQZT: "1",
          LMDM: 2462,
          SFZCJD: "N",
          FBSJ: "2023-11-17 15:59:32",
          LMMC: "基层动态",
          YXYJFK: "N",
          WZID: 1784424,
          WZLY: "国家税务总局山西省税务局",
          TITLE: "吕梁税务：“税周到”服务为民营经济赋能添力",
          SFGQ: "N",
          RN: 7,
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "晋中税务：“数据支撑”铺路 “政策找人”精准",
          DQZT: "1",
          LMDM: 2462,
          SFZCJD: "N",
          FBSJ: "2023-11-13 10:06:55",
          LMMC: "基层动态",
          YXYJFK: "N",
          WZID: 1784209,
          WZLY: "国家税务总局山西省税务局",
          TITLE: "晋中税务：“数据支撑”铺路 “政策找人”精准",
          SFGQ: "N",
          RN: 8,
        },
      ],
      ggzcList: [
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "国家税务总局 工业和信息化部关于发布《免征车辆购置税的设有固定装置的非运输专用作业车辆目录》（第十二批）的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00817",
          SFZCJD: "N",
          FBSJ: "2023-11-20 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1784897,
          WZLY: "国家税务总局 工业和信息化部",
          TITLE:
            "国家税务总局 工业和信息化部关于发布《免征车辆购置税的设有固定装置的非运输专用作业车辆目录》（第十二批）的公告",
          SFGQ: "N",
          RN: 1,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "国家税务总局山西省税务局 山西省财政厅 山西省人力资源和社会保障厅 中国人民银行山西省分行 山西省医疗保障局关于优化调整社会保险费申报缴纳流程的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00788",
          SFZCJD: "N",
          FBSJ: "2023-11-17 18:02:30",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1784441,
          WZLY: "国家税务总局山西省税务局 山西省财政厅 山西省人力资源和社会保障厅 中国人民银行山西省分行 山西省医疗保障局",
          TITLE:
            "国家税务总局山西省税务局 山西省财政厅 山西省人力资源和社会保障厅 中国人民银行山西省分行 山西省医疗保障局关于优化调整社会保险费申报缴纳流程的公告",
          SFGQ: "N",
          RN: 2,
          TYPE: "180579",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "税务总局更新发布“非接触式”办税缴费事项清单",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00779",
          SFZCJD: "N",
          FBSJ: "2023-11-15 10:22:30",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1784320,
          WZLY: "国家税务总局",
          TITLE: "税务总局更新发布“非接触式”办税缴费事项清单",
          SFGQ: "N",
          RN: 3,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "财政部 税务总局关于确认中国红十字会总会等群众团体2024年度—2026年度公益性捐赠税前扣除资格的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00811",
          SFZCJD: "N",
          FBSJ: "2023-11-13 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1784647,
          WZLY: "财政部 税务总局",
          TITLE:
            "财政部 税务总局关于确认中国红十字会总会等群众团体2024年度—2026年度公益性捐赠税前扣除资格的公告",
          SFGQ: "N",
          RN: 4,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "山西省财政厅 国家税务总局山西省税务局关于明确山西省非营利组织免税资格认定管理有关问题的通知",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00783",
          SFZCJD: "N",
          FBSJ: "2023-11-08 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1784411,
          WZLY: "山西省财政厅 国家税务总局山西省税务局",
          TITLE:
            "山西省财政厅 国家税务总局山西省税务局关于明确山西省非营利组织免税资格认定管理有关问题的通知",
          SFGQ: "N",
          RN: 5,
          TYPE: "180579",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "财政部 海关总署 税务总局关于国家综合性消防救援队伍进口税收政策的通知",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00738",
          SFZCJD: "N",
          FBSJ: "2023-10-23 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1783525,
          WZLY: "财政部 海关总署 税务总局",
          TITLE:
            "财政部 海关总署 税务总局关于国家综合性消防救援队伍进口税收政策的通知",
          SFGQ: "N",
          RN: 6,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "财政部 税务总局 中央宣传部关于延续实施文化体制改革中经营性文化事业单位转制为企业有关税收政策的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00733",
          SFZCJD: "N",
          FBSJ: "2023-10-23 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1783448,
          WZLY: "财政部 税务总局 中央宣传部",
          TITLE:
            "财政部 税务总局 中央宣传部关于延续实施文化体制改革中经营性文化事业单位转制为企业有关税收政策的公告",
          SFGQ: "N",
          RN: 7,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "财政部 税务总局关于更新中外合作油（气）田开采企业名单的通知",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00691",
          SFZCJD: "N",
          FBSJ: "2023-10-07 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1782798,
          WZLY: "财政部 税务总局",
          TITLE: "财政部 税务总局关于更新中外合作油（气）田开采企业名单的通知",
          SFGQ: "N",
          RN: 8,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "国家税务总局关于第九批全国税务领军人才学员选拔预录取考察的通告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00686",
          SFZCJD: "N",
          FBSJ: "2023-09-28 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1782455,
          WZLY: "国家税务总局",
          TITLE:
            "国家税务总局关于第九批全国税务领军人才学员选拔预录取考察的通告",
          SFGQ: "N",
          RN: 9,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "财政部 税务总局 住房城乡建设部关于保障性住房有关税费政策的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00685",
          SFZCJD: "N",
          FBSJ: "2023-09-28 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1782453,
          WZLY: "财政部 税务总局 住房城乡建设部",
          TITLE:
            "财政部 税务总局 住房城乡建设部关于保障性住房有关税费政策的公告",
          SFGQ: "N",
          RN: 10,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "财政部 税务总局关于继续对废矿物油再生油品免征消费税的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00680",
          SFZCJD: "N",
          FBSJ: "2023-09-27 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1782451,
          WZLY: "财政部 税务总局",
          TITLE: "财政部 税务总局关于继续对废矿物油再生油品免征消费税的公告",
          SFGQ: "N",
          RN: 11,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "财政部 税务总局关于延续实施医疗服务免征增值税等政策的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00673",
          SFZCJD: "N",
          FBSJ: "2023-09-26 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1782449,
          WZLY: "财政部 税务总局",
          TITLE: "财政部 税务总局关于延续实施医疗服务免征增值税等政策的公告",
          SFGQ: "N",
          RN: 12,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "财政部 税务总局关于延续实施金融机构农户贷款利息收入免征增值税政策的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00674",
          SFZCJD: "N",
          FBSJ: "2023-09-26 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1782447,
          WZLY: "财政部 税务总局",
          TITLE:
            "财政部 税务总局关于延续实施金融机构农户贷款利息收入免征增值税政策的公告",
          SFGQ: "N",
          RN: 13,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE:
            "财政部 税务总局关于延续实施中国邮政储蓄银行三农金融事业部涉农贷款增值税政策的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00675",
          SFZCJD: "N",
          FBSJ: "2023-09-26 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1782445,
          WZLY: "财政部 税务总局",
          TITLE:
            "财政部 税务总局关于延续实施中国邮政储蓄银行三农金融事业部涉农贷款增值税政策的公告",
          SFGQ: "N",
          RN: 14,
          TYPE: "180578",
        },
        {
          SFXSSJ: "Y",
          DETAILTITLE: "财政部 税务总局关于铁路债券利息收入所得税政策的公告",
          DQZT: "1",
          FBLX: "1 ",
          LMDM: 545,
          INDEXNO: "11140000012184142Y/2023-00662",
          SFZCJD: "N",
          FBSJ: "2023-09-25 00:00:00",
          LMMC: "最新文件",
          YXYJFK: "N",
          WZID: 1782443,
          WZLY: "财政部 税务总局",
          TITLE: "财政部 税务总局关于铁路债券利息收入所得税政策的公告",
          SFGQ: "N",
          RN: 15,
          TYPE: "180578",
        },
      ],

      activityTabId: 1,
    };
  },
  watch: {},
  computed: {},
  methods: {
    toApply() {
      this.$router.push({
        path: "/applyfor",
      });
    },
    serviceClick(num) {
      if (num == 1) {
        this.$router.push({
          path: "/home/gssb",
          query: { org: "home" },
        });
      }
      if (num == 2) {
        this.$router.push({
          path: "/finance_1/detail_dzfw",
          query: { org: "home" },
        });
      }
      if (num == 3) {
        this.$router.push({
          path: "/home/zffw",
          // query: { org: "home" },
        });
      }
      if (num == 4) {
        this.$router.push({
          // path: "/finance_1/detail_dzpz",
          path: "/certificateCollection",
          query: { org: "home" },
        });
      }
      if (num == 5) {
        this.$router.push({
          // path: "/home/qyyx",
          path: "/market",
          query: { org: "home" },
        });
      }
      if (num == 6) {
        this.$router.push({
          path: "/home/cjfw",
        });
      }
      if (num == 7) {
        this.$router.push({
          path: "/applyfor",
        });
      }
    },
    viewDetail(id, title) {
      sessionStorage.setItem("hdtitle", title);
      if (this.activityTabId == 1) {
        this.$router.push({
          path: "/activity/detail_hd",
          query: { id: id },
        });
      }
      if (this.activityTabId == 2) {
        this.$router.push({
          path: "/activity/detail_zc",
          query: { id: id },
        });
      }
    },
    tabItemClick(index, id) {
      this.currentIndex = index;
      this.activityTabId = id;
      if (id == 1) {
        this.tabName = "最新活动";
        this.dataNum2 = this.activityData2;
        this.dataNum3 = this.activityData3;
      }
      if (id == 2) {
        this.tabName = "公共政策";
        this.dataNum2 = this.ggzcData2;
        this.dataNum3 = this.ggzcData3;
      }
    },
    toList() {
      this.$router.push({
        path: "/activity",
        query: { id: this.activityTabId },
      });
    },
  },
  created() {
    this.dataNum2 = this.activityData2;
    this.dataNum3 = this.activityData3;
  },
  mounted() {
    let that = this; //如果下面想要使用变量，请定义
    that.mySwiper = new Swiper(".swiper-container", {
      autoplay: true, //是否自动滚动
      loop: true, //是否可以循环
      initialSlide: 0, //初始化第几页
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      }, //使用左右按钮
      // on: {
      //   transitionEnd() {
      //     //点击过渡完触发事件
      //     that.activeIndex = that.mySwiper.activeIndex; //activeIndex轮播下标
      //   },
      // }, //事件
    });
  },
};
</script>
<style scoped>
.wrapper {
}

.banner {
  width: 100%;
  height: 566px;
}

.banner img {
  display: block;
  width: 100%;
  height: 100%;
}

.container {
  padding: 60px;
}

.container_bg {
  background: #f5f5f5;
  padding: 60px;
}

.container_bg1 {
  background: #f5f5f5;
}

.box {
  width: 1200px;
  margin: 0 auto;
}

.box_title {
  text-align: center;
  font-size: 20px;
  font-weight: 600;
}

.title {
  width: 100%;
  font-size: 20px;
  font-weight: 600;
  text-align: center;
}

.hot_title {
  display: flex;
  align-items: center;
  justify-content: center;
}

.hot_icon {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}

.hot_icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.content {
  font-size: 24px;
  font-weight: 600;
}

.box_img {
  width: 100%;
  padding: 20px;
  margin-top: 20px;
}

.icon_list {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.icon_list span {
}

.icon_item {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0;
}

.icon_item img {
  /* display: block;
  width: 80%;
  height: 100%;
  margin: 0 auto; */
}

.icon_item span {
  display: block;
  margin-top: 8px;
  font-size: 14px;
  color: #333;
}

.icon {
  width: 60px;
  height: 60px;
}

.icon img {
  display: block;
  width: 100%;
  height: 100%;
}

.container_bg {
  background: #f5f5f5;
  padding: 60px;
}

.box_main {
}

.box_top {
}

.tab {
  width: 1200px;
  padding: 30px 25px 0;
  margin: 0 auto;
  display: flex;
  justify-content: flex-end;
}

.tab_list {
  display: flex;
  align-items: center;
}

.tab_item {
  padding: 10px;
  font-size: 14px;

  line-height: 1;
  white-space: nowrap;
  vertical-align: middle;
  background: #fff;
  border: 1px solid #dcdfe6;
  font-weight: 500;
  border-left: 0;
  color: #606266;
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  cursor: pointer;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding: 12px 20px;
  font-size: 14px;
}

.tab_item:first-child {
  border-left: 1px solid #dcdfe6;
  border-radius: 4px 0 0 4px;
}

.tab_item:last-child {
  border-radius: 0 4px 4px 0;
}

.tab_active {
  background: #e8f0ff;
}

.box_center {
  display: flex;
  margin-top: 20px;
  justify-content: center;
}

.left_img {
  width: 540px;
  height: 340px;
}

.left_img img {
  display: block;
  width: 100%;
  height: 100%;
}

.box_center .list {
  width: 300px;
  margin-left: 8px;
}

.box_center .list .item {
  border-radius: 8px;
  background: #fff;
  padding: 18px;
  margin-bottom: 9px;
}

.box_center .list .item:last-child {
  margin-bottom: 0;
}

.box_center .list .item .title {
  text-align: left;
  font-size: 14px;
  color: #333;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.box_center .list .item .p {
  margin-top: 10px;
  text-align: left;
  font-size: 14px;
  color: #999;
  overflow: hidden;
  display: -webkit-box; /* 为了兼容性，需要加上前缀 */
  -webkit-line-clamp: 2; /* 行数 */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.date_box {
  margin-top: 10px;
  padding-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #f5f5f5;
}

.date span {
  display: block;
}

.date span:first-child {
  font-size: 24px;
  color: #999;
  font-weight: 600;
}

.date span:last-child {
  font-size: 12px;
  color: #999;
}

.box_center .list .item:hover {
  background: #055afe;
}

.box_center .list .item:hover .title {
  color: #fff;
}

.box_center .list .item:hover .p {
  color: #fff;
}

.box_center .list .item:hover .date span {
  color: #fff;
}

.actlist {
  width: 300px;
  margin-left: 8px;
}

.actlist .actitem {
  display: flex;
  border-radius: 8px;
  background: #fff;
  padding: 22px 18px;
  margin-bottom: 9px;
}

.actlist .actitem:last-child {
  margin-bottom: 0;
}

.actlist .actitem .acttitle {
  height: 37.5px;
  text-align: left;
  font-size: 14px;
  color: #333;
  overflow: hidden;
  display: -webkit-box; /* 为了兼容性，需要加上前缀 */
  -webkit-line-clamp: 2; /* 行数 */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.actdate_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.right_title {
  flex: 1;
  padding-left: 20px;
}

.right_arrow {
  margin-top: 10px;
}

.actdate span {
  display: block;
}

.actdate span:first-child {
  font-size: 24px;
  color: #999;
  font-weight: 600;
}

.actdate span:last-child {
  font-size: 12px;
  color: #999;
}

.actitem:hover {
  background: #055afe;
}

.actitem:hover .acttitle {
  color: #fff;
}

.actitem:hover .actdate span {
  color: #fff;
}

.box_down {
}

.more {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 30px;
  cursor: pointer;
}

.more span {
  display: block;
  margin-right: 5px;
  font-size: 14px;
  color: #333;
}

.more img {
  width: 8px;
  height: 10px;
}

.content_list {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.content_list > span {
  width: 30%;
}

.content_item {
  width: 30%;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 0 8px 0 rgba(232, 237, 250, 0.6),
    0 2px 4px 0 rgba(232, 237, 250, 0.5);
  margin: 0 30px;
}

.top {
  /* border-bottom: 1px solid rgba(166, 166, 166, 0.4); */
}

.img {
  width: 100%;
  height: 180px;
}

.img img {
  display: block;
  width: 100%;
  height: 100%;
}

.content_item .name {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 600;
  color: #606266;
  margin: 8px 0;
  padding: 20px;
}

.content_item .name span {
  display: block;
  width: 28px;
  height: 1px;
  background: #055afe;
  margin-left: 10px;
}
</style>
